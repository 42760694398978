.miracumLogo {
    width: 400px;
}

.partnerLogo {
    position: relative;
    width: 250px;
    margin-bottom: 20px;
}

.aicareLogo {
    margin-right: 90px;
    width: 100px;
}

.dizLogo {
    width: 200px;
}

.distralLogo {
    max-width: 300px;
    margin-left: -20px;
}

.digitalesHessenLogo {
    width: 300px;
}

.bmgLogo {
    margin-left: -30px;
}

.contentRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contentCard {
    border: 0;
    margin-top: 20px;
    flex: 50%;
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
    .contentCard {
      flex: 100%;
    }
}