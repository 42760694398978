.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    width: 400px;
    float: left;
    height: 100vh;
    background-color: white;
}

.brand {
    position: relative;
    top: 20px;
    left: 20px;
}

@media only screen and (max-width: 768px) {
    .brand {
        max-width: 300px;
        height: auto;
        position: relative;
        left: 90px;
        top: 20px;
        z-index: 20;
    }
}

.main-nav-custom {
    position: absolute;
    top: 50%;
    z-index: 11;
    transform: translate(0%, -50%);
    left: 120px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .main-nav-custom {
        position: fixed;
        top: 60px;
    }
}
@media only screen and (max-width: 768px) {
    .toggle {
        position: absolute;
        top: 0px;
        left: -90px;
    }
}

@media only screen and (max-width: 768px) {
    .collapse {
        position: fixed;
        left: 50%;
        margin-left: -100px;
        top: 100px;
        transform: translate(-50%, 0%);
        z-index: 10;
        height: 100%;
    }
}

.main-nav-custom a.active {
    color: #0071bb;
    border-right: 3px solid #0071bb;
}

.nav-link-custom {
    text-decoration: none;
    color: rgb(81, 93, 105);
    font-size: 22px;
    margin: 15px;
    padding-right: 20px;
}

@media only screen and (max-width: 768px) {
    .nav-link-custom {
        padding-right: 20px;
    }
}

.nav-link-custom:hover {
    color: #0071bb;
}

.navIcon {
    position: relative;
    left: -20px;
    width: 25px;
}

.logoNavItems {
    position: relative;
    top: 20px;
    left: -50px;
}

.gitlabLogo {
    position: relative;
    width: 60px;
    top: 10px;
}

.toolpoolLogo {
    position: relative;
    width: 60px;
    top: 10px;
    left: 20px;
}

.zulipLogo {
    position: relative;
    top: 10px;
    left: 40px;
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 400px;
    background-color: white;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .bottom-nav {
        width: 100%;
        z-index: 0;
    }
}

.bottom-nav-link {
    text-decoration: none;
    color: rgb(81, 93, 105);
    font-size: 20px;
    margin: 20px;
}

.bottom-nav-link:hover {
    color: #0071bb;
}

.bottom-nav a.active {
    color: #0071bb;
    border-top: 3px solid #0071bb;
}

.lang-selector {
    position: relative;
    left: 20px;
}

.flag-icon {
    position: relative;
    top: -5px;
    left: -5px;
    width: 30px;
}

.dropdown-menu {
    min-width: auto !important;
    width: 55px;
}

#navbarScrollingDropdown {
    width: fit-content;
}