* {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scrollbar-width: thin;
    scrollbar-color: #0071bb #ddd;
    --proskive-blue: #0071bb;
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

html::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #0071bb 0%, #0071bb 100%);
    border-radius: 5px;
}

html::-webkit-scrollbar-track {
    background-color: #ddd;
    border: 1px solid #ddd;
}

html::-webkit-scrollbar-button {
    background-color: #0071bb;
    border-radius: 5px;
}

.center {
    text-align: center;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    height: auto;
}

.vCenter {
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}

.scrollButton {
    position: fixed;
    width: 66px;
    background-image: url('../resources/media/icons/up-arrow.png');
    background-repeat: no-repeat;
    right: 30px;
    bottom: 30px;
    height: 66px;
    z-index: 10;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .scrollButton {
        bottom: 70px;
    }
}

.content {
    background-color: white;
    margin-top: 30px;
    right: 10px;
    position: relative;
    height: auto;
    width: calc(100% - 400px);
    max-width: 1000px;
    float: left;
    left: 400px;
    padding-bottom: 20px;
    padding-right: 10px;
}

@media only screen and (max-width: 768px) {
    .content {
        width: 100%;
        left: 10px;
        top: 100px;
        padding-bottom: 50px;
    }
}

.contentHeadline {
    position: relative;
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 15px;
    margin-top: 20px;
    color: rgb(30, 77, 131);
    padding-right: 10px;
}

@media only screen and (max-width: 768px) {
    .contentHeadline {
        font-size: 36px;
    }
}

.contentSubheadline {
    position: relative;
    margin-bottom: 20px;
    top: 10px;
    font-size: 30px;
    color: rgb(36, 46, 56);
}

p {
    position: relative;
}

h1 {
    padding-left: 10px;
    padding-right: 10px;
}

h4 {
    position: relative;
    top: 5px;
    bottom: 10px;
}

h5 {
    position: relative;
    bottom: 10px;
    top: 5px;
}